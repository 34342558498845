import { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Box,
  Button,
  Typography,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemText,
  Chip,
  Modal,
  Alert,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { urls } from '../constants';

const ExpertDetailPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get('id');
  const [expert, setExpert] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [showAvailabilityDialog, setShowAvailabilityDialog] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: '', variant: '' });
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    const fetchExpertDetails = async () => {
      try {
        const response = await axios.get(`${urls.EXPERTS}${id}`);
        setExpert(response.data);
      } catch (error) {
        console.error('Error fetching expert details:', error);
      }
    };

    if (id) {
      fetchExpertDetails();
    }
  }, [id]);

  const handleToggleActivation = async () => {
    try {
      const newStatus = expert.accountStatus === 'Active' ? 'Inactive' : 'Active';
      await axios.post(`${urls.EXPERTS}activation`, { isActive: newStatus, cognitoUserId: expert.cognitoUserId });
      setExpert({ ...expert, accountStatus: newStatus });
      setAlert({ show: true, message: `User ${newStatus === 'Active' ? 'activated' : 'deactivated'} successfully.`, variant: 'success' });
    } catch (error) {
      console.error('Error toggling account status:', error);
      setAlert({ show: true, message: 'Error toggling account status.', variant: 'error' });
    } finally {
      setShowConfirmDialog(false);
    }
  };

  const handlePasswordChange = async () => {
    try {
      await axios.put(`${urls.EXPERTS}change-password`, { email: expert.email, newPassword });
      setAlert({ show: true, message: 'Password changed successfully.', variant: 'success' });
    } catch (error) {
      console.error('Error changing password:', error);
      setAlert({ show: true, message: 'Error changing password.', variant: 'error' });
    } finally {
      setShowPasswordDialog(false);
    }
  };

  const handleCloseAlert = () => {
    setAlert({ show: false, message: '', variant: '' });
  };

  const openConfirmDialog = () => {
    setShowConfirmDialog(true);
  };

  const closeConfirmDialog = () => {
    setShowConfirmDialog(false);
  };

  const openPasswordDialog = () => {
    setShowPasswordDialog(true);
  };

  const closePasswordDialog = () => {
    setShowPasswordDialog(false);
  };

  const openAvailabilityDialog = () => {
    setShowAvailabilityDialog(true);
  };

  const closeAvailabilityDialog = () => {
    setShowAvailabilityDialog(false);
  };

  if (!expert) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardHeader
              avatar={<Avatar src={expert.profilePicture || '/default-avatar.png'} alt="Profile" sx={{ width: 100, height: 100 }} />}
              title={<Typography variant="h5">{expert.displayName}</Typography>}
              subheader={expert.title}
              sx={{ textAlign: 'center' }}
            />
            <CardContent>
              <List>
                <ListItem>
                  <ListItemText primary="Email" secondary={expert.email} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Handle" secondary={expert.handle} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Location" secondary={`${expert.address}, ${expert.city}, ${expert.state}, ${expert.country.label}, ${expert.zip}`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Role" secondary={expert.isExpert ? 'Expert' : 'User'} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Joined Date" secondary={new Date(expert.joinedDate).toLocaleDateString()} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Review Score" secondary={`${expert.reviewScore} (${expert.numberOfReviews} reviews)`} />
                </ListItem>
              </List>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button variant="contained" color="primary" onClick={openPasswordDialog}>
                    Change Password
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant={expert.accountStatus === 'Active' ? 'contained' : 'outlined'}
                    color={expert.accountStatus === 'Active' ? 'error' : 'success'}
                    onClick={openConfirmDialog}
                  >
                    {expert.accountStatus === 'Active' ? 'Deactivate User' : 'Activate User'}
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={openAvailabilityDialog}>
                    View Availability
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h6">Skills</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {expert.skills.map(skill => <Chip key={skill} label={skill} color="primary" />)}
              </Box>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6">Categories</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {expert.categories.map(category => <Chip key={category} label={category} color="secondary" />)}
              </Box>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6">Languages</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {expert.languages.map(language => <Chip key={language} label={language} color="info" />)}
              </Box>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6">Bio</Typography>
              <Typography>{expert.bio}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Modal open={showConfirmDialog} onClose={closeConfirmDialog}>
        <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 1, maxWidth: 400, mx: 'auto', mt: '20%' }}>
          <Typography variant="h6">Confirm Action</Typography>
          <Typography>Are you sure you want to {expert.accountStatus === 'Active' ? 'deactivate' : 'activate'} this user?</Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="secondary" onClick={closeConfirmDialog}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={handleToggleActivation} sx={{ ml: 2 }}>Confirm</Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={showPasswordDialog} onClose={closePasswordDialog}>
        <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 1, maxWidth: 400, mx: 'auto', mt: '20%' }}>
          <Typography variant="h6">Change Password</Typography>
          <TextField
            label="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="secondary" onClick={closePasswordDialog}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={handlePasswordChange} sx={{ ml: 2 }}>Change</Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={showAvailabilityDialog} onClose={closeAvailabilityDialog}>
        <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: 1, maxWidth: 600, mx: 'auto', mt: '10%' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Availability</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Day</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expert.timings && Object.entries(expert.timings).map(([day, timing]) => (
                  <TableRow key={day}>
                    <TableCell>{day.charAt(0).toUpperCase() + day.slice(1)}</TableCell>
                    <TableCell>{`${timing.start.hh}:${timing.start.mm} ${timing.start.period}`}</TableCell>
                    <TableCell>{`${timing.end.hh}:${timing.end.mm} ${timing.end.period}`}</TableCell>
                    <TableCell>{timing.isAvailable ? 'Available' : 'Not Available'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="secondary" onClick={closeAvailabilityDialog}>Close</Button>
          </Box>
        </Box>
      </Modal>

      {alert.show && (
        <Alert severity={alert.variant} onClose={handleCloseAlert} sx={{ mt: 2 }}>
          {alert.message}
        </Alert>
      )}
    </Container>
  );
};

export default ExpertDetailPage;
