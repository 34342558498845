// src/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkLoggedIn = async () => {
      const token = localStorage.getItem('authToken');

      if (token) {
        try {
          const response = await axios.get('https://srvadm.nexper.app/check', {
            headers: { Authorization: `Bearer ${token}` }
          });
          setCurrentUser({});
        } catch (error) {
          localStorage.removeItem('authToken');
          setCurrentUser(null);
        }
      }
      setLoading(false);
    };

    checkLoggedIn();
  }, []);

  const signup = async (email, password) => {
    const response = await axios.post('/api/auth/signup', { email, password });
    localStorage.setItem('authToken', response.data.token);
    setCurrentUser({});
  };

  const login = async (email, password) => {
    const response = await axios.post('https://srvadm.nexper.app/login/', { email, password });
    localStorage.setItem('authToken', response.data.accessToken);
    setCurrentUser({});
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setCurrentUser(null);
  };

  return (
    <AuthContext.Provider value={{ currentUser, signup, login, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
