import React from 'react';
import { NavLink } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Box, Button, Menu, MenuItem, IconButton } from '@mui/material';
import { useAuth } from './AuthContext';
import { getIconComponent } from './iconUtils';
import MenuIcon from '@mui/icons-material/Menu';

const horizontalMenuData = () => [
  {
    label: 'Home',
    href: '/',
    icon: 'TablerHome',
    children: [
      {
        label: 'Analytics',
        icon: 'TablerChartLine',
        href: '/home/analytics'
      },
      {
        label: 'Reports',
        icon: 'TablerReport',
        href: '/home/reports'
      }
    ]
  },
  {
    label: 'Tasks',
    href: '/tasks',
    icon: 'TablerChecklist',
    children: [
      {
        label: 'Signups',
        icon: 'TablerUserPlus',
        href: '/tasks/signups'
      },
      {
        label: 'Support tickets',
        icon: 'TablerTicket',
        href: '/tasks/support'
      },
      {
        label: 'Payouts',
        icon: 'TablerCurrencyDollar',
        href: '/tasks/payouts'
      }
    ]
  },
  {
    label: 'About',
    href: '/about',
    icon: 'TablerInfoCircle'
  }
];

const HorizontalMenu = () => {
  const { currentUser } = useAuth();
  const menuData = horizontalMenuData();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState({});

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuOpen = (event, index) => {
    setSubMenuAnchorEl((prev) => ({ ...prev, [index]: event.currentTarget }));
  };

  const handleSubMenuClose = (index) => {
    setSubMenuAnchorEl((prev) => ({ ...prev, [index]: null }));
  };

  if (!currentUser) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {menuData.map((menuItem, index) => {
        const IconComponent = getIconComponent(menuItem.icon);

        return menuItem.children ? (
          <Box key={index} sx={{ mr: 2 }}>
            <Button
              color="inherit"
              onClick={(e) => handleSubMenuOpen(e, index)}
              startIcon={<IconComponent />}
              sx={{ display: { xs: 'none', md: 'flex' }, textTransform: 'none', px: 2 }}
            >
              {menuItem.label}
            </Button>
            <Menu
              anchorEl={subMenuAnchorEl[index]}
              open={Boolean(subMenuAnchorEl[index])}
              onClose={() => handleSubMenuClose(index)}
              sx={{ '& .MuiMenuItem-root': { py: 2 } }} // Adding spacing to dropdown menu items
            >
              {menuItem.children.map((subMenuItem, subIndex) => {
                const SubIconComponent = getIconComponent(subMenuItem.icon);

                return (
                  <MenuItem
                    key={subIndex}
                    component={NavLink}
                    to={subMenuItem.href}
                    onClick={() => handleSubMenuClose(index)}
                    sx={{ py: 1, px: 3 }} // Adding spacing to sub-menu items
                  >
                    <Box display="flex" alignItems="center">
                      <SubIconComponent className="me-2" />
                      {subMenuItem.label}
                    </Box>
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
        ) : (
          <Button
            key={index}
            component={NavLink}
            to={menuItem.href}
            color="inherit"
            startIcon={<IconComponent />}
            sx={{ display: { xs: 'none', md: 'flex' }, textTransform: 'none', px: 2, mr: 2 }}
          >
            {menuItem.label}
          </Button>
        );
      })}
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleMenuOpen}
        sx={{ display: { xs: 'flex', md: 'none' } }}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{ '& .MuiMenuItem-root': { py: 2 } }} // Adding spacing to dropdown menu items
      >
        {menuData.map((menuItem, index) => {
          const IconComponent = getIconComponent(menuItem.icon);

          return menuItem.children ? (
            <MenuItem
              key={index}
              onClick={(e) => handleSubMenuOpen(e, index)}
              sx={{ py: 1, px: 3 }} // Adding spacing to menu items
            >
              <Box display="flex" alignItems="center">
                <IconComponent className="me-2" />
                {menuItem.label}
              </Box>
              <Menu
                anchorEl={subMenuAnchorEl[index]}
                open={Boolean(subMenuAnchorEl[index])}
                onClose={() => handleSubMenuClose(index)}
                sx={{ '& .MuiMenuItem-root': { py: 2 } }} // Adding spacing to sub-menu items
              >
                {menuItem.children.map((subMenuItem, subIndex) => {
                  const SubIconComponent = getIconComponent(subMenuItem.icon);

                  return (
                    <MenuItem
                      key={subIndex}
                      component={NavLink}
                      to={subMenuItem.href}
                      onClick={handleMenuClose}
                      sx={{ py: 1, px: 3 }} // Adding spacing to sub-menu items
                    >
                      <Box display="flex" alignItems="center">
                        <SubIconComponent className="me-2" />
                        {subMenuItem.label}
                      </Box>
                    </MenuItem>
                  );
                })}
              </Menu>
            </MenuItem>
          ) : (
            <MenuItem
              key={index}
              component={NavLink}
              to={menuItem.href}
              onClick={handleMenuClose}
              sx={{ py: 1, px: 3 }} // Adding spacing to menu items
            >
              <Box display="flex" alignItems="center">
                <IconComponent className="me-2" />
                {menuItem.label}
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default HorizontalMenu;
