export const BASE_URL = 'https://srvadm.nexper.app/'

export const urls = {
    SIGNUPS : `${BASE_URL}signups?sort=latest`,
    GET_PAYMENTS: `${BASE_URL}payouts/get-experts-due-payments`,
    GET_REFUNDS: `${BASE_URL}payouts/get-payment-refund-details`,
    PROCESS_PAYMENTS: `${BASE_URL}payouts/process-payments`,
    OPEN_TICKETS: `${BASE_URL}tickets/count/open`,
    INPROGRESS_TICKETS: `${BASE_URL}tickets/count/in-progress`,
    TODAY_TICKETS: `${BASE_URL}tickets/count/today`,
    GET_TICKETS: `${BASE_URL}tickets`,
    EXPERTS : `${BASE_URL}experts/`,
    
    
}