import React from 'react';

const Tasks = () => {
  return (
    <div>
      <h1>Tasks</h1>
      <p>Tasks overview.</p>
    </div>
  );
};

export default Tasks;
