import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { urls } from '../constants';

const Page = () => {
  const [ticket, setTicket] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [isImage, setIsImage] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const id = query.get('id');

  useEffect(() => {
    if (id) {
      axios
        .get(`${urls.GET_TICKETS}/${id}`)
        .then((response) => {
          setTicket(response.data.ticket);
        })
        .catch((error) => {
          console.error('There was an error fetching the ticket data: ', error);
        });
    }
  }, [id]);

  const handleResponseSubmit = () => {
    const formData = new FormData();
    formData.append('message', responseMessage);
    formData.append('respondedBy', 'support');
    formData.append('username', 'SupportUser'); // Replace with the actual username
    if (file) {
      formData.append('file', file);
    }

    axios
      .post(`${urls.GET_TICKETS}/${id}/response`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        setTicket(response.data.ticket);
        setResponseMessage('');
        setFile(null);
      })
      .catch((error) => {
        console.error('There was an error posting the response: ', error);
      });
  };

  const handleStatusChange = (newStatus) => {
    axios
      .patch(`${urls.GET_TICKETS}/${id}/status`, {
        status: newStatus,
      })
      .then((response) => {
        setTicket(response.data.ticket);
      })
      .catch((error) => {
        console.error('There was an error updating the ticket status: ', error);
      });
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleOpenDialog = (url, isImageFile) => {
    setImageSrc(url);
    setIsImage(isImageFile);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setImageSrc('');
  };

  const isImageFile = (fileName) => {
    if (!fileName) return false;
    const extension = fileName.split('.').pop().toLowerCase();
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            {ticket ? (
              <>
                <Typography variant="h6" gutterBottom>
                  {ticket.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {ticket.description}
                </Typography>
                <Divider style={{ marginBottom: '20px' }} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong>Status:</strong> {ticket.status}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Type:</strong> {ticket.type}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong>Created At:</strong> {new Date(ticket.createdAt).toLocaleString()}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Updated At:</strong> {new Date(ticket.updatedAt).toLocaleString()}
                    </Typography>
                  </Grid>
                </Grid>
                {ticket.screenshotUrl && (
                  <Box mt={2}>
                    {isImageFile(ticket.fileName) ? (
                      <Button variant="outlined" onClick={() => handleOpenDialog(ticket.screenshotUrl, true)}>
                        View Attachment
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        component="a"
                        href={ticket.screenshotUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download Attachment
                      </Button>
                    )}
                  </Box>
                )}
                <Typography variant="h6" style={{ marginTop: '20px' }}>
                  Responses
                </Typography>
                <Paper variant="outlined" style={{ maxHeight: 400, overflow: 'auto', marginTop: '10px' }}>
                  {ticket.responses && ticket.responses.length > 0 ? (
                    ticket.responses.map((response) => (
                      <Box key={response._id} p={2} borderBottom={1} borderColor="divider">
                        <Typography variant="body2">
                          <strong>{response.respondedBy}</strong> ({response.username}) -{' '}
                          {new Date(response.createdAt).toLocaleString()}
                        </Typography>
                        <Typography variant="body1" paragraph>
                          {response.message}
                        </Typography>
                        {response.screenshotUrl && (
                          <Box mt={1}>
                            {isImageFile(response.screenshot) ? (
                              <Button variant="outlined" onClick={() => handleOpenDialog(response.screenshotUrl, true)}>
                                View Attachment
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                component="a"
                                href={response.screenshotUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Download Attachment
                              </Button>
                            )}
                          </Box>
                        )}
                      </Box>
                    ))
                  ) : (
                    <Typography variant="body2" align="center" p={2}>
                      No responses yet.
                    </Typography>
                  )}
                </Paper>
                <TextField
                  label="Response"
                  value={responseMessage}
                  onChange={(e) => setResponseMessage(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  style={{ marginTop: '20px' }}
                />
                <input type="file" onChange={handleFileChange} style={{ marginTop: '10px' }} />
                <Box mt={2}>
                  <Button variant="contained" color="primary" onClick={handleResponseSubmit}>
                    Post Response
                  </Button>
                  {ticket.status === 'OPEN' ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleStatusChange('COMPLETED')}
                      style={{ marginLeft: '10px' }}
                    >
                      Close Ticket
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleStatusChange('OPEN')}
                      style={{ marginLeft: '10px' }}
                    >
                      Open Ticket
                    </Button>
                  )}
                </Box>
              </>
            ) : (
              <Typography>Loading...</Typography>
            )}
          </CardContent>
        </Card>
      </Grid>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Attachment</DialogTitle>
        <DialogContent>
          {isImage ? (
            <img src={imageSrc} alt="Attachment" style={{ width: '100%' }} />
          ) : (
            <Typography variant="body1">
              <a href={imageSrc} target="_blank" rel="noopener noreferrer">
                Download Attachment
              </a>
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Page;
