// src/Profile.js
import React from 'react';
import { useAuth } from './AuthContext';
import { Container } from 'react-bootstrap';

const Profile = () => {
  const { currentUser } = useAuth();

  return (
    <Container className="mt-5">
      <h2>Profile Page</h2>
      <p>Welcome, {currentUser.email}. This is your profile page.</p>
    </Container>
  );
};

export default Profile;
