import {
    Home as TablerHome,
    ChartLine as TablerChartLine,
    Report as TablerReport,
    Checklist as TablerChecklist,
    UserPlus as TablerUserPlus,
    Ticket as TablerTicket,
    CurrencyDollar as TablerCurrencyDollar,
    InfoCircle as TablerInfoCircle
  } from 'tabler-icons-react';
  
  const Icons = {
    TablerHome,
    TablerChartLine,
    TablerReport,
    TablerChecklist,
    TablerUserPlus,
    TablerTicket,
    TablerCurrencyDollar,
    TablerInfoCircle
  };
  
  export const getIconComponent = (iconName) => {
    return Icons[iconName] || null;
  };
  