// src/Dashboard.js
import React, { useEffect } from 'react';
import { useAuth } from './AuthContext';
import { Container, Button } from 'react-bootstrap';

const Dashboard = () => {
  const { logout } = useAuth();

  return (
    <Container className="mt-5">
      <h2>Dashboard</h2>
    </Container>
  );
};

export default Dashboard;
