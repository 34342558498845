import React, { useState } from 'react';
import { BrowserRouter as Router, NavLink } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import { AppBar, Toolbar, Typography, Container, Box, IconButton, Menu, MenuItem, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AppRoutes from './AppRoutes';
import HorizontalMenu from './HorizontalMenu';
import { FaSignInAlt, FaSignOutAlt, FaTachometerAlt, FaUser } from 'react-icons/fa';
import './customStyles.css'; // Import the custom CSS

function App() {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Router>
      <AuthProvider>
        <AppBar position="static">
          <Container>
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component={NavLink}
                to="/"
                sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, color: 'inherit', textDecoration: 'none' }}
              >
                NEXPER
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Menu</Typography>
                  </MenuItem>
                </Menu>
              </Box>
              <Typography
                variant="h6"
                noWrap
                component={NavLink}
                to="/"
                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, color: 'inherit', textDecoration: 'none' }}
              >
                NEXPER
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <HorizontalMenu />
                <AuthLinks />
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <LogoutLink />
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Container className="mt-3">
          <AppRoutes />
        </Container>
      </AuthProvider>
    </Router>
  );
}

const AuthLinks = () => {
  const { currentUser } = useAuth();

  return (
    <>
      {currentUser && (
        <>
          <Button component={NavLink} to="/dashboard" color="inherit" sx={{ display: { xs: 'none', lg: 'flex' } }}>
            <FaTachometerAlt className="me-2" /> Dashboard
          </Button>
          <Button component={NavLink} to="/profile" color="inherit" sx={{ display: { xs: 'none', lg: 'flex' } }}>
            <FaUser className="me-2" /> Profile
          </Button>
          {/* Show only icons on small screens */}
          <IconButton component={NavLink} to="/dashboard" color="inherit" sx={{ display: { xs: 'flex', lg: 'none' } }}>
            <FaTachometerAlt />
          </IconButton>
          <IconButton component={NavLink} to="/profile" color="inherit" sx={{ display: { xs: 'flex', lg: 'none' } }}>
            <FaUser />
          </IconButton>
        </>
      )}
    </>
  );
};

const LogoutLink = () => {
  const { currentUser, logout } = useAuth();

  return (
    <>
      {currentUser ? (
        <Button onClick={logout} color="inherit" sx={{ display: { xs: 'none', lg: 'flex' } }}>
          <FaSignOutAlt className="me-2" /> Logout
        </Button>
      ) : (
        <Button component={NavLink} to="/login" color="inherit" sx={{ display: { xs: 'none', lg: 'flex' } }}>
          <FaSignInAlt className="me-2" /> Login
        </Button>
      )}
      {/* Show only icons on small screens */}
      {currentUser ? (
        <IconButton onClick={logout} color="inherit" sx={{ display: { xs: 'flex', lg: 'none' } }}>
          <FaSignOutAlt />
        </IconButton>
      ) : (
        <IconButton component={NavLink} to="/login" color="inherit" sx={{ display: { xs: 'flex', lg: 'none' } }}>
          <FaSignInAlt />
        </IconButton>
      )}
    </>
  );
};

export default App;
