import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { toast, Toaster } from 'react-hot-toast';
import { urls } from '../constants';

const Payouts = () => {
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [weeks, setWeeks] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState('');
  const [payouts, setPayouts] = useState([]);
  const [selectedPayoutDetails, setSelectedPayoutDetails] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    setMonths(monthNames);

    const currentYear = new Date().getFullYear();
    const yearRange = Array.from({ length: 10 }, (_, i) => currentYear - i);
    setYears(yearRange);
  }, []);

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      const monthIndex = months.indexOf(selectedMonth);
      const startDate = new Date(selectedYear, monthIndex, 1);
      const endDate = new Date(selectedYear, monthIndex + 1, 0);
      const weeksInMonth = [];

      let currentWeekStart = startDate;
      while (currentWeekStart < endDate) {
        const weekEnd = new Date(currentWeekStart);
        weekEnd.setDate(weekEnd.getDate() + 6);

        if (weekEnd > endDate) {
          weekEnd.setDate(endDate.getDate());
        }

        weeksInMonth.push({ start: new Date(currentWeekStart), end: new Date(weekEnd) });

        currentWeekStart.setDate(currentWeekStart.getDate() + 7);
      }

      setWeeks(weeksInMonth);
    }
  }, [selectedMonth, selectedYear]);

  const handleProcessClick = () => {
    if (selectedWeek) {
      axios
        .post(urls.GET_PAYMENTS, {
          start: selectedWeek.start,
          end: selectedWeek.end,
        })
        .then((response) => {
          setPayouts(response.data);
          toast.success('Payouts fetched successfully.');
        })
        .catch((error) => {
          console.error('Error fetching payouts:', error);
          toast.error('Error fetching payouts.');
        });
    }
  };

  const handleViewDetails = (expertId, currency) => {
    axios
      .post(`${urls.GET_REFUNDS}`, {
        start: selectedWeek.start,
        end: selectedWeek.end,
        expertId,
        currency,
      })
      .then((response) => {
        setSelectedPayoutDetails({
          ...response.data,
          expertId,
          currency,
        });
        setOpenDialog(true);
      })
      .catch((error) => {
        console.error('Error fetching payment and refund details:', error);
        toast.error('Error fetching payment and refund details.');
      });
  };

  const handleProcessPayment = (expertId, currency) => {
    axios
      .post(urls.PROCESS_PAYMENTS, {
        expertId,
        currency,
        payments: selectedPayoutDetails.payments,
        refunds: selectedPayoutDetails.refunds,
        netPayable:
          (selectedPayoutDetails.payments.reduce((total, payment) => total + payment.amount, 0) -
            selectedPayoutDetails.refunds.reduce((total, refund) => total + refund.amount, 0)) /
          100,
        start: selectedWeek.start,
        end: selectedWeek.end,
      })
      .then((response) => {
        console.log('Payment processed successfully:', response.data);
        toast.success('Payment processed successfully.');
        handleCloseDialog();
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error('Payout already processed for this period.');
        } else {
          toast.error('Error processing payment.');
        }
        console.error('Error processing payment:', error);
      });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedPayoutDetails(null);
  };

  const columns = [
    { field: 'expertId', headerName: 'Expert ID', width: 150 },
    { field: 'currency', headerName: 'Currency', width: 150 },
    { field: 'totalPayments', headerName: 'Total Payments', width: 150 },
    { field: 'totalRefunds', headerName: 'Total Refunds', width: 150 },
    { field: 'netPayable', headerName: 'Net Payable', width: 150 },
    { field: 'numberOfPayments', headerName: 'Number of Payments', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 300,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginRight: 16 }}
            onClick={() => handleViewDetails(params.row.expertId, params.row.currency)}
          >
            View Details
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => handleProcessPayment(params.row.expertId, params.row.currency)}
          >
            Process
          </Button>
        </>
      ),
    },
  ];

  const rows = payouts.map((payout, index) => ({
    id: `${payout.expertId}-${payout.currency}`,
    expertId: payout.expertId,
    currency: payout.currency,
    totalPayments: payout.totalPayments / 100,
    totalRefunds: payout.totalRefunds / 100,
    netPayable: (payout.totalPayments - payout.totalRefunds) / 100,
    numberOfPayments: payout.payments.length,
  }));

  return (
    <Container maxWidth="lg">
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Experts Payouts" />
            <CardContent>
              <TextField
                select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                id="year-select"
                style={{ width: '250px' }}
                label="Select Year"
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                id="month-select"
                style={{ width: '250px', marginLeft: '16px' }}
                label="Select Month"
              >
                {months.map((month, index) => (
                  <MenuItem key={index} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </TextField>
              {selectedMonth && selectedYear && (
                <TextField
                  select
                  value={selectedWeek}
                  onChange={(e) => setSelectedWeek(e.target.value)}
                  id="week-select"
                  style={{ width: '250px', marginLeft: '16px' }}
                  label="Select Week"
                >
                  {weeks.map((week, index) => (
                    <MenuItem key={index} value={week}>
                      {week.start.toDateString()} - {week.end.toDateString()}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <Button
                variant="contained"
                type="submit"
                sx={{ mr: 4, marginTop: '16px' }}
                onClick={handleProcessClick}
              >
                Show
              </Button>
              {payouts.length > 0 && (
                <div style={{ marginTop: '16px', height: 400, width: '100%' }}>
                  <h3>Payout Records Grouped by Experts and Currency</h3>
                  <DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5]} />
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <DialogTitle>Payment and Refund Records</DialogTitle>
          <DialogContent>
            {selectedPayoutDetails && (
              <>
                <Typography variant="h6" gutterBottom>
                  Net Payable:{' '}
                  {(selectedPayoutDetails.payments.reduce((total, payment) => total + payment.amount, 0) -
                    selectedPayoutDetails.refunds.reduce((total, refund) => total + refund.amount, 0)) /
                    100}{' '}
                  {selectedPayoutDetails.currency.toUpperCase()}
                </Typography>
                <h4>Payments</h4>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Payment Intent ID</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Currency</TableCell>
                      <TableCell>Due On</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedPayoutDetails.payments.map((payment, index) => (
                      <TableRow key={index}>
                        <TableCell>{payment.paymentIntentId}</TableCell>
                        <TableCell>{(payment.amount / 100).toFixed(2)}</TableCell>
                        <TableCell>{payment.currency?.toUpperCase()}</TableCell>
                        <TableCell>{new Date(payment.dueOn).toLocaleDateString()}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <h4>Refunds</h4>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Refund Intent ID</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Currency</TableCell>
                      <TableCell>Due On</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedPayoutDetails.refunds.map((refund, index) => (
                      <TableRow key={index}>
                        <TableCell>{refund.paymentIntentId}</TableCell>
                        <TableCell>{(refund.amount / 100).toFixed(2)}</TableCell>
                        <TableCell>{refund.currency?.toUpperCase()}</TableCell>
                        <TableCell>{new Date(refund.dueOn).toLocaleDateString()}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleProcessPayment(selectedPayoutDetails.expertId, selectedPayoutDetails.currency)}
                  style={{ marginTop: '16px' }}
                >
                  Process Payments
                </Button>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Toaster position="top-right" reverseOrder={false} />
      </Grid>
    </Container>
  );
};

export default Payouts;
