import { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Button, Container, Grid, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Pagination, Box, Typography, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { urls } from '../constants';

const Signups = () => {
  const [signups, setSignups] = useState([]);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [handleFilter, setHandleFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(1);
  const [totalExperts, setTotalExperts] = useState(0);
  const [filterParams, setFilterParams] = useState({ startDate: '', endDate: '' });
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserStatus, setSelectedUserStatus] = useState('');

  const navigate = useNavigate();

  const fetchSignups = async (filterParams, pageNum, pageSize) => {
    setSignups([]);
    setData([]);

    try {
      const response = await axios.get(urls.SIGNUPS, {
        params: {
          startDate: filterParams.startDate,
          endDate: filterParams.endDate,
          pageNum,
          pageSize,
        },
      });

      const { experts, totalExperts } = response.data;
      setSignups(experts);
      setTotalExperts(totalExperts);

      if (experts.length === 0) {
        setData([]);
      }
    } catch (error) {
      console.error('Error fetching signups:', error);
      setSignups([]);
      setData([]);
    }
  };

  useEffect(() => {
    fetchSignups(filterParams, pageNum, pageSize);
  }, [filterParams, pageNum, pageSize]);

  useEffect(() => {
    if (signups.length > 0) {
      const updatedSignups = signups.map((signup, index) => ({
        ...signup,
        sNo: (pageNum - 1) * pageSize + index + 1,
        id: signup._id,
        joinedDate: signup.joinedDate ? new Date(signup.joinedDate).toLocaleDateString() : '',
        country: signup.country?.label || '',
        isExpert: signup.isExpert !== undefined ? signup.isExpert : false,
        accountStatus: signup.accountStatus || 'Inactive',
      }));

      setData(updatedSignups);
    } else {
      setData([]);
    }
  }, [signups]);

  useEffect(() => {
    setPageNum(1);
  }, [handleFilter, emailFilter]);

  const handleToggleActivation = async () => {
    try {
      const newStatus = selectedUserStatus === 'Active' ? 'Inactive' : 'Active';
      await axios.post(`${urls.EXPERTS}activation`, { isActive: newStatus, cognitoUserId: selectedUserId });
      setSignups(signups.map(signup => signup.cognitoUserId === selectedUserId ? { ...signup, accountStatus: newStatus } : signup));
      setSnackbarMessage(`User ${newStatus === 'Active' ? 'activated' : 'deactivated'} successfully.`);
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error toggling account status:', error);
      setSnackbarMessage('Error toggling account status.');
      setOpenSnackbar(true);
    } finally {
      setConfirmDialogOpen(false);
    }
  };

  const handleView = (id) => {
    navigate(`/tasks/signups/expertdetail?id=${id}`);
  };

  const handleDateFilter = () => {
    setPageNum(1);
    setFilterParams({ startDate, endDate });
  };

  const handlePageChange = (event, value) => {
    setPageNum(value);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const openConfirmDialog = (id, currentStatus) => {
    setSelectedUserId(id);
    setSelectedUserStatus(currentStatus);
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const resetFilters = () => {
    setStartDate('');
    setEndDate('');
    setHandleFilter('');
    setEmailFilter('');
    setFilterParams({ startDate: '', endDate: '' });
    setPageNum(1);
  };

  const filteredData = data.filter((row) =>
    row.handle.toLowerCase().includes(handleFilter.toLowerCase()) &&
    row.email.toLowerCase().includes(emailFilter.toLowerCase())
  );

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <Box p={2}>
              <Typography variant="h5">Expert Signups</Typography>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={3}>
                  <TextField
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Handle"
                    value={handleFilter}
                    onChange={(e) => setHandleFilter(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Email"
                    value={emailFilter}
                    onChange={(e) => setEmailFilter(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2} display="flex" alignItems="center">
                  <Button variant="contained" color="primary" onClick={handleDateFilter} fullWidth>
                    Filter
                  </Button>
                </Grid>
                <Grid item xs={2} display="flex" alignItems="center">
                  <Button variant="contained" color="secondary" onClick={resetFilters} fullWidth>
                    Reset Filters
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Handle</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Joined Date</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Expert</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((row, index) => (
                    <TableRow key={row._id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.handle}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.joinedDate}</TableCell>
                      <TableCell>{row.country}</TableCell>
                      <TableCell>{row.isExpert ? 'Yes' : 'No'}</TableCell>
                      <TableCell>{row.accountStatus}</TableCell>
                      <TableCell>
                        <Button variant="outlined" size="small" onClick={() => handleView(row.id)} className="me-2">
                          View
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          color={row.accountStatus === 'Active' ? 'error' : 'success'}
                          onClick={() => openConfirmDialog(row.cognitoUserId, row.accountStatus)}
                        >
                          {row.accountStatus === 'Active' ? 'Deactivate' : 'Activate'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={2} display="flex" justifyContent="center">
              <Pagination
                count={Math.ceil(totalExperts / pageSize)}
                page={pageNum}
                onChange={handlePageChange}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
      <Dialog
        open={confirmDialogOpen}
        onClose={closeConfirmDialog}
      >
        <DialogTitle>Confirm {selectedUserStatus === 'Active' ? 'Deactivation' : 'Activation'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {selectedUserStatus === 'Active' ? 'deactivate' : 'activate'} this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleToggleActivation} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Signups;
