import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Login from './Login';
import Dashboard from './Dashboard';
import Home from './components/Home';
import Analytics from './components/Analytics';
import Reports from './components/Reports';
import Tasks from './components/Tasks';
import Signups from './components/Signups';
import SupportTickets from './components/SupportTickets';
import Payouts from './components/Payouts';
import About from './components/About';
import Profile from './Profile';
import ExpertDetailPage from './components/ExpertDetailPage';
import ViewTicket from './components/ViewTicket';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/home/analytics"
        element={
          <PrivateRoute>
            <Analytics />
          </PrivateRoute>
        }
      />
      <Route path="/tasks/signups/expertdetail" element={
            <PrivateRoute>
              <ExpertDetailPage />
            </PrivateRoute>
          } />
      <Route
        path="/home/reports"
        element={
          <PrivateRoute>
            <Reports />
          </PrivateRoute>
        }
      />
      <Route
        path="/tasks"
        element={
          <PrivateRoute>
            <Tasks />
          </PrivateRoute>
        }
      />
      <Route
        path="/tasks/signups"
        element={
          <PrivateRoute>
            <Signups />
          </PrivateRoute>
        }
      />
      <Route
        path="/tasks/support"
        element={
          <PrivateRoute>
            <SupportTickets />
          </PrivateRoute>
        }
      />
      <Route
        path="/tasks/ticket"
        element={
          <PrivateRoute>
            <ViewTicket />
          </PrivateRoute>
        }
      />

      <Route
        path="/tasks/payouts"
        element={
          <PrivateRoute>
            <Payouts />
          </PrivateRoute>
        }
      />
      <Route
        path="/about"
        element={
          <PrivateRoute>
            <About />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
