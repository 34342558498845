import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Container, Grid,CardContent, TextField, Select, MenuItem, InputLabel, FormControl, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Typography } from '@mui/material';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import { urls } from '../constants';

const RowOptions = ({ id, onDelete }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleViewDetail = () => {
    navigate(`/tasks/ticket?id=${id}`);
  };

  const handleEdit = () => {
    navigate(`/ticket/edit?id=${id}`);
  };

  const handleDelete = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = () => {
    onDelete(id);
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleViewDetail}>
        <FaEye />
      </IconButton>
      <IconButton onClick={handleEdit}>
        <FaEdit />
      </IconButton>
      <IconButton onClick={handleDelete}>
        <FaTrash />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Close Ticket</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to close this ticket? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const SupportTickets = () => {
  const [ticketCounts, setTicketCounts] = useState({
    open: 0,
    inProgress: 0,
    today: 0,
  });
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [pageSize, setPageSize] = useState(5);
  const [pageNum, setPageNum] = useState(0); // Note: PageNum should start from 0
  const [totalTickets, setTotalTickets] = useState(0);
  const [ticketStatus, setTicketStatus] = useState('open');

  const fetchTicketCounts = async () => {
    try {
      const [openResponse, inProgressResponse, todayResponse] = await Promise.all([
        axios.get(urls.OPEN_TICKETS),
        axios.get(urls.INPROGRESS_TICKETS),
        axios.get(urls.TODAY_TICKETS),
      ]);
      setTicketCounts({
        open: openResponse.data.totalCount,
        inProgress: inProgressResponse.data.totalCount,
        today: todayResponse.data.totalCount,
      });
    } catch (error) {
      console.error('Error fetching ticket counts:', error);
    }
  };

  const fetchTickets = async (startDate, endDate, pageNum, pageSize, status) => {
    setData([]);

    try {
      const response = await axios.get(urls.GET_TICKETS, {
        params: {
          startDate,
          endDate,
          pageNum: pageNum + 1, // Increment pageNum by 1 for backend pagination
          pageSize,
          status,
        },
      });
      const { tickets, totalTickets } = response.data;
      setData(tickets);
      setTotalTickets(totalTickets);
    } catch (error) {
      console.error('Error fetching tickets:', error);
      setData([]);
    }
  };

  useEffect(() => {
    fetchTicketCounts();
  }, []);

  useEffect(() => {
    fetchTickets(startDate, endDate, pageNum, pageSize, ticketStatus);
  }, [startDate, endDate, pageNum, pageSize, ticketStatus]);

  const handleDelete = async (id) => {
    try {
      await axios.patch(`${urls.GET_TICKETS}/${id}/status`, { status: 'CLOSED' });
      fetchTickets(startDate, endDate, pageNum, pageSize, ticketStatus);
    } catch (error) {
      console.error('Error closing ticket:', error);
    }
  };

  const formatDateTime = (date) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(date).toLocaleString('en-US', options);
  };

  const handlePageChange = (event, newPage) => {
    setPageNum(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNum(0);
  };

  const handleFilter = () => {
    setPageNum(0);
    fetchTickets(startDate, endDate, 0, pageSize, ticketStatus);
  };

  return (
    <Container>
      <Grid container spacing={3} className="mb-3">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5">Ticket Summary</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body1">Open Tickets: {ticketCounts.open}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1">In Progress Tickets: {ticketCounts.inProgress}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1">Today's Tickets: {ticketCounts.today}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <FormControl fullWidth className="mb-3">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      label="Start Date"
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="End Date"
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={ticketStatus}
                        onChange={(e) => setTicketStatus(e.target.value)}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="open">Open</MenuItem>
                        <MenuItem value="inProgress">In Progress</MenuItem>
                        <MenuItem value="closed">Closed</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} className="d-flex align-items-end">
                    <Button variant="contained" color="primary" onClick={handleFilter} fullWidth>
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Handle</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Last Updated</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow key={row._id}>
                        <TableCell>{row.userId}</TableCell>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>{formatDateTime(row.createdAt)}</TableCell>
                        <TableCell>{formatDateTime(row.updatedAt)}</TableCell>
                        <TableCell>
                          <RowOptions id={row._id} onDelete={handleDelete} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container justifyContent="space-between" alignItems="center" className="mt-3">
                <Grid item>
                  <FormControl>
                    <Select value={pageSize} onChange={handlePageSizeChange}>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <TablePagination
                    component="div"
                    count={totalTickets}
                    page={pageNum}
                    onPageChange={handlePageChange}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handlePageSizeChange}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SupportTickets;
